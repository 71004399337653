@use "../../theme" as *;

.tabButton {
    @include rounded($radius-4, "b");
    @include rounded($radius-4, "l");
    @include linear-gradient("silver", false);
    min-width: 50px;
    text-align: center;
    border: 0px;
    margin-right: 3px;
    padding: 0px;
    cursor: pointer;

    &.active {
        @include dropshadow-1;
        color: $primary-0;

        .close-btn {
            display: inline;
        }
    }

    &.danger {
        box-shadow: unset;
        color: red
    }

    >.inner-btn {
        border: 0;
        background: transparent;
        padding: 10px 15px;
        font-size: 14px;
    }

    >.close-btn {
        display: none;
    }
}

.dark-theme {
    .tabButton {
        // @include linear-gradient("silver", false);
        background-image: unset;
        color: $primary-dark-0;
        background-color: colorRange($primary-dark-0, 1);

        &.active {
            background-color: colorRange($primary-dark-0, 6);
            color: $white;

            .close-btn {
                background-color: colorRange($black, 12) !important;
                color: $primary-light-0 !important;
            }
        }
    }
}
.signal_str1 {
    stroke: white;
    stroke-width: 70px;
    stroke-linejoin: round;
    stroke-miterlimit: 2.61313;
}

.signal_str0 {
    stroke: lightgrey;
    stroke-width: 70px;
    stroke-linejoin: round;
    stroke-miterlimit: 4px;
    &.active {
        stroke: black;
    }
}

.dark-theme {
    .signal_str0 {
        stroke: grey;
        &.active {
            stroke: white;
        }
    }
}
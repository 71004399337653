@use "../../theme" as *;

#lightDarkBtn:focus{
//   outline: 1px solid #FFFFFF;
  border-radius: 50px;
}
.dl_button_str0 {stroke:white;stroke-width:0;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.61313;fill:none;transition:1s;}
    #lightDarkBtn.active .dl_button_str0{
      stroke:#FFCC00;
    }
    .dl_button4 {fill:white;transition:1s;}
    #lightDarkBtn.active .dl_button4 {
      transform: translateX(10%);
      opacity: 0;
    }
    .dl_button9 {
      fill:black;
      transition:1s;
      transform: translateX(-10%);
      opacity: 0;
    }
    #lightDarkBtn.active .dl_button9 {
      transform: translateX(0%);
      opacity: 1;
    }
    .moon,.moonDots{
      transition:1s;
    }
    .moonDots{
      transform-origin: 22% 50%;
    }
    #lightDarkBtn.active .moon{
      transform: translateX(56%);
    }
    #lightDarkBtn.active .moonDots{
      opacity: 0;
      transform: rotate(360deg);
    }
    .dl_button1 {fill:#C9F3FF;fill-opacity:0.200000;transition:1s;}
    #lightDarkBtn.active .dl_button1{
      fill: #FFF9A3;
    }
    .dl_button2 {fill:#C9F3FF;transition:1s;}
    #lightDarkBtn.active .dl_button2{
      fill: #FFF9A3;
    }
    .stars {fill:white;transition:1s;}
    #lightDarkBtn.active .stars {transform: translateX(5%); opacity:0;}
    .dl_button6 {fill:#FFCC00;transition:1s;opacity: 0;}
    #lightDarkBtn.active .dl_button6 {opacity: 1;}

    .dl_button3 {fill:#96D0E0}
    
    .dl_button0 {fill:url(#dl_button_id4)}
    .clouds {fill:white;transition:1s;opacity: 0;transform: translateX(-5%);}
    #lightDarkBtn.active .clouds {transform: translateX(0%); opacity: 1;}

    .dl_button7 {fill:#FFF9A3;fill-opacity:0.200000}
    .dl_button8 {fill:#FFF9A3}
    .dl_button_fnt {font-weight:bold;font-size:327.3px;font-family:'Arial'}

    .dark-theme{
      .userdropdown.split-l{
        @include splitDark("l");
      }
    }
@use "../../theme" as *;

// Layout styling

// .fa-bell {
//     font-size: 30px;
// }




// DefaultHeader

.topContainer {
    @include dropshadow-1;
    // @include linear-gradient("silver", false);
    background-position: 0% 180%;
    background-size: 100% 500%;
    background-image:
        radial-gradient(circle at 80% 0%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 80% 100%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 90% 40%,
            rgba(255, 144, 107, 0.2),
            transparent 5%),
        radial-gradient(circle at 25% 70%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 15% 30%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        radial-gradient(circle at 30% 0%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        radial-gradient(circle at 30% 100%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        radial-gradient(circle at 20% 5%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 30% 50%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        radial-gradient(circle at 75% 50%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 75% 18%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        linear-gradient(45deg, colorRange($white, 15), colorRange($white, 10)),
    ;
    animation: bubblesMove 20s;
    position: fixed;
    display: flex;
    justify-content: space-between;
    height: 50px;
    top: 5px;
    left: 5px;
    right: 5px;
    z-index: 1000;
    @include rounded($radius-3, "xy");

    @include media-query($xxl) {
        height: 35px;
        top: 2.5px;
        left: 2.5px;
        right: 2.5px;
        @include rounded($radius-1, "xy");
    }

    >.blurContainer {
        position: absolute;
        @include blur($blur2);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        @include rounded($radius-3, "xy");

        @include media-query($xxl) {
            @include rounded($radius-1, "xy");
        }
    }

    a {
        text-decoration: none;

        img {
            height: 100%;
            margin-left: 60px;
            padding: 8px 0;

            @include media-query($xxl) {
                margin-left: 40px;
            }

            @include media-query($sm) {
                margin-left: 40px;
                padding: 5px 0;
            }
        }
    }

    .mainSearchContainer {
        align-self: center;
        flex: 1;
        max-width: 700px;
        margin: 0 20px;
        padding: 5px;
        background-color: $white;
        @include rounded($radius-2, "xy");
        @include dropshadow(false, 0px, 0px, 10px, colorRange($black, 1));

        @include media-query($xxl) {
            padding: 2px;
            @include rounded($radius-1, "xy");
        }

        @include media-query($md) {
            margin: 0 10px;
        }

        @include media-query($sm) {
            display: none;
        }

        .mainSearch {
            background-color: transparent;
            border: 1px solid $light;
            padding: 5px 10px;
            width: 100%;
            font-size: 14px;
            @include rounded($radius-2, "xy");
            @include dropshadow(false, 0px, 0px, 0px, colorRange($primary-0, 1));
            transition: $transition-1;

            &::placeholder {
                color: linear-gradient($primary-0, $primary-1);

            }

            &:focus {
                @include dropshadow(false, 0px, 0px, 10px, colorRange($primary-1, 20));
                outline: 1px solid $primary-1;
            }

            @include media-query($xxl) {
                padding: 2px 7px;
                font-size: 12px;
            }
        }

        .searchItems {
            @include dropshadow-1;
            background-color: $white;
            padding: 2px 7px;
            margin-top: 3px;
            display: block;
            color: $dark;
            @include rounded($radius-1, "xy");

            &:hover {
                background-color: $light;
            }
        }
    }

    .titleName {
        line-height: 90px;
        font-size: 50px;
        text-align: center;
        flex: 1;
    }

    .right-btns {
        // background-color:  gradient("linear", "45", $primary-dark-0, $primary-dark-1);
        // background: linear-gradient(to right,$primary-0,$primary-1);
        background: white;
        @include rounded($radius-3, "xy");
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        margin: 2.5px;

        @include media-query($xxl) {
            padding: 0 7.5px;
            @include rounded($radius-2, "xy");
        }

        >button,
        >div>button {
            background-color: transparent;
            border: 0;
            outline: 0;
            padding: 0;
            width: 40px;
            font-size: 20px;

            @include media-query($xxl) {
                width: 30px;
                font-size: 13px;
            }

            @include media-query($sm) {
                font-size: 12px;
            }
        }

        >.searchButton {
            display: none;

            @include media-query($sm) {
                display: block;
            }
        }

        >.bellbtn {
            &.bell-notification {
                position: relative;

                &::after {
                    content: attr(current-count);
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    color: white;
                    font-weight: bold;
                    background: red;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 10px;
                    top: -10px;
                    right: -10px;
                }
            }
        }

        .mySignll {
            width: 20px;
            margin: 0 10px;

            @include media-query($xxl) {
                width: 15px;
            }
        }

        // .user-icon {

        //     @include media-query($md) {
        //         align-self: center;
        //     }
        // }

    }

    .notifDropdownContainer {
        width: 400px;
        max-height: 500px;
        overflow: auto;
        padding: 10px 20px;

        @include media-query($xxl) {
            padding: 5px 10px;
        }

        @include media-query($md) {
            width: calc(100% - 5px);
            max-height: calc(100vh - 45px);
        }

        >h5 {
            font-weight: bold;

            @include media-query($xxl) {
                font-size: 14px;
            }
        }

    }

    .userDropdownContainer {
        width: 350px;

        @include media-query($xxl) {
            width: 190px;
        }
    }

    .userdropdown {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        @include split("b");
        background-color: transparent;
        font-size: 14px;
        text-align: left;
        padding: 8px 15px;
        display: flex;
        align-items: center;

        @include media-query($xxl) {
            padding: 5px 12px;
            font-size: 10px;
        }

        &:hover {
            background-color: $light;
        }
    }
}

@keyframes bubblesMove {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 0% 180%;
    }
}

.menubtn {
    @include rounded($radius-2, "xy");
    background-color: colorRange($primary-1, 20);
    @include linear-gradient("primary", false);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 7.5px;
    left: 7.5px;
    z-index: 10000;
    cursor: pointer;
    width: 45px;
    height: 45px;

    @include media-query($xxl) {
        @include rounded($radius-1, "xy");
        top: 5px;
        left: 5px;
        width: 30px;
        height: 30px;
    }

    i {
        color: $white;
        transition: $transition-0-5;
        font-size: 18px;

        @include media-query($xxl) {
            font-size: 14px;
        }
    }

    &.open {
        @include media-query($md) {
            background-color: colorRange($black, 12);
        }

        i {
            @include media-query($md) {
                color: $white;
            }
        }
    }
}



.secondaryLogo {
    height: 30px;
    align-self: center;
    margin-right: 10px;
}



// Sidebar styling
#sideContainer {
    @include dropshadow-1;
    @include blur($blur2);
    @include rounded($radius-3, "xy");
    background-position: 300% 300%;
    background-size: 500% 500%;
    background-image:
        radial-gradient(circle at 80% 0%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 80% 100%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 90% 40%,
            rgba(255, 144, 107, 0.2),
            transparent 5%),
        radial-gradient(circle at 25% 70%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 15% 30%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        radial-gradient(circle at 30% 0%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        radial-gradient(circle at 30% 100%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        radial-gradient(circle at 20% 5%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 30% 50%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        radial-gradient(circle at 75% 50%,
            rgba(27, 118, 187, 0.2),
            transparent 5%),
        radial-gradient(circle at 75% 18%,
            rgba(241, 90, 39, 0.2),
            transparent 5%),
        linear-gradient(45deg, colorRange($white, 15), colorRange($white, 10)),
    ;
    animation: bubblesMove1 20s;
    position: fixed;
    z-index: 1020;
    top: 60px;
    left: 5px;
    min-height: calc(100% - 65px);
    // overflow: hidden;
    transition: $transition-0-5;
    width: 50px;
    padding: 5px;

    @include media-query($xxl) {
        @include rounded($radius-1, "xy");
        width: 35px;
        top: 40px;
        left: 2.5px;
        padding: 2.5px;
        min-height: calc(100% - 42.7px);
    }

    @include media-query($md) {
        width: 0px;
        padding: 0px;
    }

    .sideSearch {
        background-color: transparent;
        border: 1px solid grey;
        padding: 0px 10px;
        width: 100%;
        font-size: 14px;
        @include rounded($radius-3, "xy");

        @include media-query($xxl) {
            font-size: 11px;
        }

        &Btn {
            font-size: 14px;

            @include media-query($xxl) {
                padding: 0;
                font-size: 11px;
                line-height: 25px;
                width: 30px;
            }
        }
    }

    .navIcon {
        padding: 0 30px 0 5px !important;

        @include media-query($md) {
            padding: 0 30px 0 5px;
        }

        &.fa-circle {
            font-size: 8px;

            @include media-query($xxl) {
                font-size: 5px;
            }
        }
    }

    .navbtn {
        font-size: 14px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: $black;
        list-style-type: none;
        display: block;
        align-items: center;
        padding: 10px 10px 10px 7.5px;
        margin-bottom: 4px;
        transition: $transition-0-5;
        width: 40px;
        overflow: hidden;
        @include rounded($radius-2, "xy");
        cursor: pointer;

        @include media-query($xxl) {
            @include rounded($radius-1, "xy");
            font-size: 11px !important;
            width: 30px;
            padding: 7px 7px 7px 5px;
        }

        @include media-query($md) {
            padding: 0px;
            width: 0px;
        }

        i,
        span {
            color: $black;
            // transition: $transition-0-5;
        }

        &:hover,
        &.active {
            @include dropshadow-1;
            transition: $transition;
            @include linear-gradient("primary", false);
            @include blur($blur5);
            color: $white !important;
            text-decoration: none;

        }

        &:hover i,
        &:hover span,
        &.active i,
        &.active span {
            color: $white;
            @include rounded($radius-2, "xy");
        }

        &:hover {
            width: 230px;

            @include media-query($xxl) {
                width: 200px;
            }
        }

    }

    &.open {
        width: 250px;

        @include media-query($xxl) {
            width: 200px;
        }

        @include media-query($md) {
            width: calc(100% - 10px);
            padding: 5px;
        }

        .navbtn {
            width: 240px;

            @include media-query($xxl) {
                width: 195px;
            }

            @include media-query($md) {
                width: 100%;
                padding: 7.5px;
            }

        }
    }

    a {
        text-decoration: none !important;

        img {
            margin: 5px 0 5px 60px;
            height: 40px;
            filter: brightness(100);

            @include media-query($md) {
                height: 30px;
            }
        }
    }
}

@keyframes bubblesMove1 {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 300% 300%;
    }
}

.sub-navbtn {
    @include rounded($radius-2, "xy");
    background-color: $dark-white;

    @include media-query($xxl) {
        @include rounded($radius-1, "xy");
    }

    a {
        font-size: 14px;
        padding: 5px 10px;
        color: $dark;
        width: 100%;

        @include media-query($xxl) {
            font-size: 10px;
        }

        &:hover {
            background-color: colorRange($primary-0, 1);
        }

        &.active {
            color: $primary-0;
            background-color: colorRange($primary-0, 1);

        }
    }
}

// Sidebar End


main {
    margin-left: 55px;
    padding-top: 60px;
    min-height: calc(100vh - 50px);

    // height: 100px;
    @include media-query($xxl) {
        margin-left: 37.5px;
        padding-top: 40px;
    }

    @include media-query($md) {
        margin-left: 0px;
    }
}

.footer {
    display: flex;
    justify-content: center;
    padding: 10px 0;

    img {
        height: 30px;

        @include media-query($sm) {
            height: 20px;
        }
    }

    @include media-query($sm) {
        font-size: 12px;
    }
}

.dark-theme {
    .topContainer {
        @include linear-gradient("dark", false);
        animation: none;

        .mainSearchContainer {
            background-color: colorRange($primary-1, 5);

            .mainSearch {
                color: $white;
                border: 2px solid $primary-dark-0;

                &::placeholder {
                    color: $white;
                }
            }

            .searchItems {
            background-color: colorRange($primary-1, 8);

                // background-color: $dark;
                color: $black;

                &:hover {
                    background-color: $primary-0;
                }
            }
        }

        a {
            img {
                padding: 5px 0;
                filter: brightness(2.5)
                // height: calc(100% - 10px);
                    // padding: 2.5px;
                    // background-color: white;
                    // @include rounded($radius-1, "xy");
                    // margin: 0 0 0 60px;
                    // @include media-query($md) {
                    //     margin: 0 0 0 40px;
                    // }
            }
        }

        .right-btns {

            // background-color: #00042c !important;
            background-color: colorRange($black, 10);

            >button,
            >div>button {
                color: $white;
            }
        }
    }

    .user-icon {
        color: $white;
    }

    #sideContainer {
        background-position: center;
        background-size: 100%;
        @include gradient("linear", "45", colorRange($dark, 10), colorRange($dark, 18));
        animation: none
    }

    .userdropdown {
        @include splitDark("b");
        color: $white;

        &:hover {
            background-color: $dark;
        }
    }

    .sideSearch {
        color: white;
    }

    .navbtn {
        color: $white !important;

        i,
        span {
            color: $white !important;
        }
    }

    .sub-navbtn {
        margin-left: 10px !important;

    }

    .sub-navbtn a {
        color: white !important;

        // color: white;
        background-color: $dark;

        &:hover {
            @include dropshadow-1;
            transition: $transition;
            @include linear-gradient("dark", false);
            @include blur($blur5);
            // color: $white !important;
            text-decoration: none;
            // color: black !important;
        }
        &.active {
             color: $primary-light-0 !important;
             @include dropshadow-1;
             transition: $transition;
             @include linear-gradient("dark", false);
             @include blur($blur5);
           
        }

    }
}
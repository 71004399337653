@use "theme" as *;
/* [ FONT ]*/
// @font-face {
//     font-family: Poppins-Regular;
//     src: url("assets/fonts/Poppins-Regular.ttf");
// }
// @font-face {
//     font-family: Poppins-SemiBold;
//     src: url("assets/fonts/Poppins-SemiBold.ttf");
// }
// @font-face {
//     font-family: Poppins-Bold;
//     src: url("assets/fonts/Poppins-Bold.ttf");
// }
// @font-face {
//     font-family: Poppins-Bolder;
//     src: url("assets/fonts/Poppins-ExtraBold.ttf");
// }
// @font-face {
//     font-family: Poppins-Light;
//     src: url("assets/fonts/Poppins-Light.ttf");
// }
// @font-face {
//     font-family: Poppins-Lighter;
//     src: url("assets/fonts/Poppins-ExtraLight.ttf");
// }
/* [ FONT ]*/

/* Styling */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(#9202C5, #1E73BD);
    width: 5px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #9202C5;
}

* {
    scroll-behavior: smooth;
}

body {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: Arial;
    background-color: $light;
    transition: background-color 1s;

    >iframe {
        display: none;
    }
}

#root {
    min-height: 100vh;
    height: 100px;
}

.position-unset {
    position: unset !important;
}

.primary-gradient,
.text-primary-gradient {
    @include gradient("linear", "135", $primary-0, $primary-1);
    color: $white;
}

.warning-gradient,
.text-warning-gradient {
    @include gradient("linear", "135", $warning-0, $warning-1);
}

.danger-gradient,
.text-danger-gradient {
    @include gradient("linear", "135", $danger-0, $danger-1);
    color: $white;
}

.success-gradient,
.text-success-gradient {
    @include gradient("linear", "135", $success-0, $success-1);
    color: $white;
}

.silver-gradient,
.text-silver-gradient {
    @include gradient("linear", "135", colorRange($white, 15), colorRange($white, 20));
}

.text-primary-gradient,
.text-warning-gradient,
.text-danger-gradient,
.text-success-gradient,
.text-silver-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-primary-0 {
    color: $primary-0
}
.text-light{
    color: $primary-light-0 !important;

}
.text-primary-1 {
    color: $primary-1
}

.text-success-0 {
    color: $success-0
}

.text-success-1 {
    color: $success-1
}

.text-danger-0 {
    color: $danger-0
}

.text-danger-1 {
    color: $danger-1
}

.text-warning-0 {
    color: $warning-0
}

.text-warning-1 {
    color: $warning-1
}

.primary-gradient-hv {
    @include gradient("linear", "135", $primary-0, $primary-1);
    background-size: 150%;
    background-position-x: 0%;
    background-position-y: 75%;
    background-repeat: no-repeat;

    &:hover {
        background-position-x: 100%;
    }
}

.warning-gradient-hv {
    @include gradient("linear", "135", $warning-0, $warning-1);

    &:hover {
        @include gradient("linear", "135", colorRange($warning-0, 10), colorRange($warning-1, 10));
    }
}

.danger-gradient-hv {
    @include gradient("linear", "135", $danger-0, $danger-1);

    &:hover {
        @include gradient("linear", "135", colorRange($danger-0, 10), colorRange($danger-1, 10));
    }
}

.success-gradient-hv {
    @include gradient("linear", "135", $success-0, $success-1);

    &:hover {
        @include gradient("linear", "135", colorRange($success-0, 10), colorRange($success-1, 10));
    }
}

.dropshadow {
    @include dropshadow(false, 10px, 15px, 20px, colorRange($black, 2));
}

.dropshadow-inset {
    @include dropshadow(true, 0px, 2px, 5px, colorRange($black, 6));
}

// .primary-glass {
//     border-width: 2px;
//     border-color: white;
//     filter:  drop-shadow(10px 20px 20px $primary-1+99);
//     background-color: rgba(255,255,255,0.7);
//     backdrop-filter: blur(20px);
// }

@include xyProp("rounded", "radius", $radius);
@include xyProp("rounded-0", "radius", $radius-0);
@include xyProp("rounded-1", "radius", $radius-1);
@include xyProp("rounded-2", "radius", $radius-2);
@include xyProp("rounded-3", "radius", $radius-3);
@include xyProp("rounded-4", "radius", $radius-4);
@include xyProp("rounded-5", "radius", $radius-5);
@include xyProp("rounded-circle-px", "radius", $radius-px);
@include xyProp("split", "border", "1px solid " + $light);
@include xyProp("border-light-1", "border", $lightborder-1);
@include xyProp("border-trans-1", "border", $transborder-1);
@include xyProp("border-light-2", "border", $lightborder-2);
@include xyProp("border-trans-2", "border", $transborder-2);

.blur {
    @include blur(5px);
}

.blur-1 {
    @include blur(10px);
}

.blur-2 {
    @include blur(20px);
}

.blur-3 {
    @include blur(30px);
}

.blur-4 {
    @include blur(40px);
}

.blur-5 {
    @include blur(50px);
}

.fade-in {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    transition: opacity $time, transform $time, visibility $time-0;

    >div {
        max-height: unset;
        transition: max-height $time-0;
    }
}

.fade-out {
    visibility: collapse;
    opacity: 0;
    transform: translateY(-30px);
    transition: opacity $time-0-1, transform $time-0-1, visibility $time;
    transition-delay: $time;

    >div {
        max-height: 0px !important;
        transition: max-height $time;
        transition-delay: $time;
    }
}

.btn-outline-0 {
    outline: 0 !important
}

.MuiStepper-root {
    padding: 0 0 10px 0 !important;
}

.MuiFormControl-root {
    >.MuiOutlinedInput-root {
        @extend .rounded-circle-px;

        >.MuiInputBase-input {
            padding: 10px 14px;

        }
    }

    &:hover .MuiFormLabel-root {
        color: $primary-0;
    }

    &:hover .MuiInputBase-root .PrivateNotchedOutline-root-1 {
        // border-color: rgb(75, 177, 255);
        border-color: $primary-0;

    }

    .MuiFormLabel-root {
        // color: rgb(75, 177, 255);
        color: $primary-1;
        // color: $primary-0;
        // color: rgb(27, 117, 188);
    }

    .MuiInputBase-root {
        .PrivateNotchedOutline-root-1 {
            border-color: $primary-1;
        }

        @include media-query($xxl) {
            font-size: 14px;
        }
    }
}

.my-wifi {
    background: no-repeat url(./assets/svgs/my-icons/wifi-OnOff.svg);
    background-size: 100% 200%;
    transition: $transition-1;
    background-position-x: center;
    display: block;
    padding: 10px;

    &.on {
        background-position-y: 0%;
    }

    &.off {
        background-position-y: 100%;
    }
}

.connectionDate {
    font-size: 80%;
}

.h-unset {
    height: unset !important;
}

.fade-in-left-onload {
    animation: fade-left-onload 1s ease-in;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateX(-10%);
}

.fade-out-left-onload {
    animation: fade-left-onload 1s reverse ease-out;
    animation-fill-mode: forwards;
    opacity: 1;
    transform: translateX(0%);
}

.fade-in-left-onload1 {
    animation: fade-left-onload 0.3s ease-in;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateX(-10%);
}

.fade-out-left-onload1 {
    animation: fade-left-onload 0.3s reverse ease-out;
    animation-fill-mode: forwards;
    opacity: 1;
    transform: translateX(0%);
}

.fade-in-right-onload {
    animation: fade-right-onload 1s ease-in;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateX(10%);
}

.fade-out-right-onload {
    animation: fade-right-onload 1s reverse ease-out;
    animation-fill-mode: forwards;
    opacity: 1;
    transform: translateX(0%);
}

.fade-in-right-onload1 {
    animation: fade-right-onload 0.3s ease-in;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateX(10%);
}

.fade-out-right-onload1 {
    animation: fade-right-onload 0.3s reverse ease-out;
    animation-fill-mode: forwards;
    opacity: 1;
    transform: translateX(0%);
}

.fade-in-top-onload {
    animation: fade-top-onload 1s ease-in;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateY(-10%);
}

.fade-out-top-onload {
    animation: fade-top-onload 1s reverse ease-out;
    opacity: 1;
    transform: translateY(0%);
    animation-fill-mode: forwards;
}

.fade-in-bottom-onload {
    animation: fade-bottom-onload 1s ease-in;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateY(10%);
}

.fade-out-bottom-onload {
    animation: fade-bottom-onload 1s reverse ease-out;
    animation-fill-mode: forwards;
    opacity: 1;
    transform: translateY(0%);
}

@keyframes fade-left-onload {
    0% {
        opacity: 0;
        overflow: hidden;
        transform: translateX(-10%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }

}

@keyframes fade-right-onload {
    0% {
        opacity: 0;
        overflow: hidden;
        transform: translateX(10%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }

}

@keyframes fade-top-onload {
    0% {
        opacity: 0;
        overflow: hidden;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }

}

@keyframes fade-bottom-onload {
    0% {
        opacity: 0;
        overflow: hidden;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }

}

.text-unselectable {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
}

.text-link {
    color: blue;
}

.btn-light {
    border: 0;
    background-color: $primary-light-0 !important;

    &:hover {
        background-color: $primary-light-0-hv !important;

    }
}

.bg-light {
    background-color: $primary-light-0 !important;
}

/* Dark mode styles */
.dark-theme {
    // @include gradient("radial", "135", #020d24, $black);
    // background-image: radial-gradient($primary-dark-0,#020d24);
    background-color: #00042c;
    //   background-color: #020d24;
    color: $white;

    .primary-gradient {
        @include gradient("linear", "135", $primary-dark-0, $primary-dark-1);
    }

    .silver-gradient {
        @include gradient("linear", "45", colorRange($primary-dark-0, 3), colorRange($primary-dark-1, 3));
    }

    .text-primary {
        color: $primary-0 !important;
    }

    .text-link {
        color: $primary-dark-1;
    }

    .text-muted {
        color: #6abaff !important;
    }

    .btn-light {

        background-color: #00042c !important;
        color: $white !important;
    }
    .bg-light {

        background-color: #00042c !important;
        color: $white !important;
    }
    @include xyProp("split", "border", "1px solid " + $dark);

    .my-wifi {
        background-image: url(./assets/svgs/my-icons/wifi-OnOff-dark.svg);
    }

    .MuiFormControl-root {
        .MuiFormLabel-root {
            color: $primary-1 ;
        }

        .MuiFormLabel-root.Mui-disabled {
            color: $grey;
        }

        &:hover .MuiFormLabel-root {
            color: $white;
        }

        &:hover .MuiFormLabel-root.Mui-disabled {
            color: $grey;
        }

        .MuiInputBase-root {
            color: $white;

            .PrivateNotchedOutline-root-1 {
                border-color: $primary-1;
            }
        }

        .MuiInputBase-root.Mui-disabled {
            color: $grey;

            .PrivateNotchedOutline-root-1 {
                border-color: $grey;
            }
        }



        &:hover .MuiInputBase-root {
            .PrivateNotchedOutline-root-1 {
                border-color: $white;
            }
        }

        &:hover .MuiInputBase-root.Mui-disabled {
            .PrivateNotchedOutline-root-1 {
                border-color: $grey;
            }
        }
    }

    .MuiStepLabel-label {
        color: $white !important;
    }
}
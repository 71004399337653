@use '../../theme' as *;

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 100%;
    text-align: center;
    position: relative;

    .heading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 350%;
        color: $white;
    }
}
@use "../../theme" as *;

.myCard,
.myCard-blur {
    @include dropshadow-1;
    @include rounded($radius-4, "xy");
    @include xyProps("border", "xy", "" + $lightborder-2);

    // Desktop (lg) - Larger than 900px screens
    @include media-query($lg) {
        @include rounded($radius-2, "xy");
    }

    // Mobile (sm) - Less than 600px screens
    @include media-query($sm) {
        @include rounded($radius-3, "xy");
    }

    .header {
        padding: 10px 20px 0px 20px;
        @include media-query($lg) {
            padding: 5px 10px 0px 10px;
        }
        @include media-query($sm) {
            padding: 5px 8px 0px 8px;
        }
    }

    .spleater {
        @include split("b");
        margin: 10px 20px -1px 20px;
        @include media-query($lg) {
            margin: 8px 15px -1px 15px;
        }
        @include media-query($sm) {
            margin: 5px 10px -1px 10px;
        }
    }

    .heading-1 {
        font-size: 20px;
        font-weight: bold;
        font-family: Arial;
        color: $dark;
        @include media-query($lg) {
            font-size: 18px;
        }
        @include media-query($sm) {
            font-size: 16px;
        }
    }

    .heading-2 {
        font-size: 14px;
        font-weight: bold;
        font-family: Arial;
        color: $dark;
        @include media-query($lg) {
            font-size: 12px;
        }
        @include media-query($sm) {
            font-size: 10px;
        }
    }

    .innerCard {
        background-color: $white;
        height: 100%;
        @include dropshadow-1;
        @include rounded($radius-2, "xy");
        @include media-query($lg) {
            @include rounded($radius-1, "xy");
        }
        @include media-query($sm) {
            @include rounded($radius-3, "xy");
        }
    }

    .cardBody {
        .collapse {
            height: 0px;
        }
    }
}

.myCard-blur {
    @include blur($blur2);
}

.dark-theme {
    .myCard,
    .myCard-blur {
        @include xyProps("border", "xy", "0px");
        
        .header {
            padding: 10px 20px 10px 20px;
            @include media-query($lg) {
                padding: 8px 15px 8px 15px;
            }
            @include media-query($sm) {
                padding: 5px 8px 5px 8px;
            }
        }
        
        .spleater {
            @include xyProps("border", "xy", "0px");
            margin: 0px;
        }
        
        .heading-1 {
            color: $light;
        }
        
        .heading-2 {
            color: $light;
        }
    
        .innerCard {
            background-color: colorRange($black,10);
        }
    }
}

@use "../../theme" as *;
.chartOuter{
    overflow: hidden;
    height: 350px;
    margin: 0 15px 15px 0;
    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
        opacity: 1;
        width: unset;
        line-height: unset;
        min-height: unset;
        padding: 8px 15px;
        display: flex;
        color: $success-0;
        z-index: 0;
        @include dropshadow-1;
        background-color: $white;
        // @include linear-gradient("success",true);
        @include rounded($radius-px, "xy");
      
    }
    .chartInner{
        margin-bottom: -20px;
    }
}
.amcharts-amexport-menu .amcharts-amexport-menu-level-1{
    @include rounded($radius-4, "y");
    @include rounded($radius-4, "l");
    @include rounded($radius-1, "r");
    @include blur($blur2);
    @include linear-gradient("silver",true);
    @include xyProps("border","xy", "" + $lightborder-2);
    @include dropshadow-1;
    right: 10px !important;
    padding:  10px 0;
    overflow: hidden;
    .amcharts-amexport-item{
        background-color: transparent !important;
        text-align: center;
        a{
            padding: 5px 20px;
            &:hover{
                background-color: $light;
            }
        }
    }
}
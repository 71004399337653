@use "../../theme" as *;

.dropdown-container{
    position: relative;
    // z-index: ;
    .dropdown-body{
        position: absolute;
        top: 120%;
        // margin-top: 20%;
        // padding: 10px 0;
        overflow: hidden;
        z-index: 2;
        // @include linear-gradient("silver",false);
        @include xyProps("border","xy",$lightborder-2);
        // background-color: $primary-light-0;  
        @include dropshadow-1;
        @include blur($blur2);
        @include media-query($xxl) {
            top: 110%;
        }
        &.left{
            left: 5px;
            @include rounded($radius-4,"x");
            @include rounded($radius-4,"b");
            @include rounded($radius-1,"t");
            @include media-query($xxl) {
                left: 2.5px;
                @include rounded($radius-2,"x");
                @include rounded($radius-2,"b");
            }
        }
        &.right{
            right: 5px;
            @include rounded($radius-4,"y");
            @include rounded($radius-4,"l");
            @include rounded($radius-1,"r");
            @include media-query($xxl) {
                right: 2.5px;
                @include rounded($radius-2,"y");
                @include rounded($radius-2,"l");
            }
        }
        button{
            outline: 0 !important;
        }
    }
}

.dark-theme {
    .dropdown-container{
        .dropdown-body{
        background-color: #00042c;  

            @include linear-gradient("dark",false);
            @include xyProps("border","xy","2px solid " + $dark);
        }
    }
}
@use '../../theme' as *;

.array-button {
  min-width: 150px;
  height: 35px;
  overflow: hidden;
  position: relative;
  @include dropshadow-1;
  @include rounded($radius-px, "xy");
  cursor: pointer;
  // border: 3px solid $light;
  box-sizing: unset;

  >.buttonFlex {
    display: flex;
    @include dropshadow-1;
    @include rounded($radius-px, xy);

    &.primary {
      @include linear-gradient("primary", false);
    }

    &.success {
      @include linear-gradient("success", false);
    }

    &.danger {
      @include linear-gradient("danger", false);
    }

    &.warning {
      @include linear-gradient("warning", false);
    }

    &.light {
      @include linear-gradient("silver", false);

      .togglebtn {
        color: black !important;
      }
    }
  }

  .selectbtn {
    @include dropshadow-1;
    @include rounded($radius-px, xy);
    background-color: colorRange($black, 7);
    transition: all 0.5s;
  }

  .togglebtn {
    color: $white;
    line-height: 35px;
    // min-width: 80px;
    flex: auto;
    text-align: center;
    padding: 0 20px;
  }
}

.flat-container {
  padding: 5px;
  @include rounded($radius-px, xy);
  display: flex;

  &.light {
    color: $dark;
    background-color: $light;
  }

  .togglebtn {
    line-height: 12px;
    flex: auto;
    text-align: center;
    padding: 0 5px;
    cursor: pointer;


    &.success {
      color: $success-0;
    }

    &.warning {
      color: $warning-0;
    }

    &.danger {
      color: $danger-0;
    }

    &.primary {
      color: $primary-0;
    }

  }
}
.dark-theme{
  .flat-container {
    &.light {
      color: $dark;
      background-color: $dark;
    }
  }
}
@use "../../theme" as *;
.myPopup{
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 100;
    .myPopupBack{
        height: 100%;
        width: 100%;
        background-color: colorRange($black,1);
        position: absolute;
        @include blur($blur)

    }
    .myPopupInner{
        margin: 150px auto 10px auto;
    }
}
.dark-theme{
    .myPopupBack{
        @include blur($blur2);
        background-color: colorRange($black,10);
    }
    .myPopupInner{
        .myCard-blur{
            padding-top: 5px;
        }
        .header{
            background-color: colorRange($primary-dark-0, 6);
            margin: 0px 5px;
            padding: 5px;
            @include rounded($radius-1, "xy");
        }
    }
}
/* Function & Variable */
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$white: #ffffff;
$light: rgb(240, 240, 240);
$black: #000000;
$dark: #3d3d3d;
$grey: #646464;
$muted: #878787;

// $primary-0: #0d3a5d;
// $primary-1: #1b75bc;

$primary-0: #AC00EB;
$primary-1: #1791FC;
$warning-0: #ff5900;
$warning-1: #ffa600;
$danger-0: #c21c12;
$danger-1: #f16544;
$success-0: #009933;
$success-1: #1fb833;


// Dark Theme
$dark-white: #bbbbbb;
$primary-dark-0: #d561ff;
$primary-dark-1: #00e1ff;
$warning-dark-0: #ff5900;
$warning-dark-1: #ffa600;
$danger-dark-0: #c21c12;
$danger-dark-1: #f16544;
$success-dark-0: #009933;
$success-dark-1: #1fb833;

$colorTrans: 00, 0D, 1A, 26, 33, 40, 4D, 59, 66, 73, 80, 8C, 99, A6, B3, BF, CC, D9, E6, F2, FF;


$time-0: 0s;
$time-0-1: 0.1s;
$time: 0.3s;
$time-0-5: 0.5s;
$time-1: 1s;
$time-2: 2s;
$transition-0: all $time-0;
$transition: all $time;
$transition-0-1: all $time-0-1;
$transition-0-5: all $time-0-5;
$transition-1: all $time-1;
$transition-2: all $time-2;

$radius-0: 0px;
$radius: 5px;
$radius-1: 8px;
$radius-2: 14px;
$radius-3: 20px;
$radius-4: 25px;
$radius-5: 50px;
$radius-px: 10000px;

$lightborder-1: 1px solid $white;
$lightborder-2: 2px solid $white;
$lightborder-3: 3px solid $white;
$lightborder-4: 4px solid $white;
$lightborder-5: 5px solid $white;
$darkborder-1: 1px solid $dark;
$transborder-1: 1px solid transparent;
$darkborder-2: 2px solid $dark;
$transborder-2: 2px solid transparent;

$light-blur: 2px;
$blur: 5px;
$blur1: 10px;
$blur2: 20px;
$blur3: 30px;
$blur4: 40px;
$blur5: 50px;

$shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
// $shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

//btn
// $primary-light-0: #{""+$primary-0 + nth($colorTrans,1+1)+""};
$primary-light-0: #faf0ff;
$primary-light-0-hv: #f5e0ff;


@mixin border($range) {
    backdrop-filter: blur($range);
}

@mixin blur($range) {
    backdrop-filter: blur($range);
}

@mixin media-query($size) {
    @media (max-width: $size) {
        @content;
    }
}

@function colorRange($color, $range) {
    // @return $color + nth($colorTrans,);
    @return #{""+$color + nth($colorTrans,$range+1)+""};
}

@mixin dropshadow($inset, $x, $y, $blur, $color) {
    @if($inset) {
        box-shadow: inset $x $y $blur $color;
    }

    @else {
        box-shadow: $x $y $blur $color;
    }
}

@mixin dropshadow-hv($start, $end) {
    box-shadow: $shadow;
    transition: $end;

    &:hover {
        box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.1);
        transition: $start;
    }
}

@mixin gradient($type, $deg, $start, $end) {
    background-image: #{$type+"-gradient("+$deg+"deg,"+ $start+","+ $end+")"}
}

@mixin xyProp($class, $key, $value) {
    @if($key =="radius") {
        #{"."+$class+"-t,."+$class+"-y,."+$class} {
            #{"border-top-left-"+$key}: #{$value};
        }

        #{"."+$class+"-b,."+$class+"-y,."+$class} {
            #{"border-bottom-right-"+$key}: #{$value};
        }

        #{"."+$class+"-l,."+$class+"-x,."+$class} {
            #{"border-bottom-left-"+$key}: #{$value};
        }

        #{"."+$class+"-r,."+$class+"-x,."+$class} {
            #{"border-top-right-"+$key}: #{$value};
        }
    }

    @else {
        #{"."+$class+"-t,."+$class+"-y,."+$class} {
            #{$key+"-top"}: #{$value} !important;
        }

        #{"."+$class+"-b,."+$class+"-y,."+$class} {
            #{$key+"-bottom"}: #{$value} !important;
        }

        #{"."+$class+"-l,."+$class+"-x,."+$class} {
            #{$key+"-left"}: #{$value} !important;
        }

        #{"."+$class+"-r,."+$class+"-x,."+$class} {
            #{$key+"-right"}: #{$value} !important;
        }
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}




@mixin dropshadow-1 {
    @include dropshadow(false, 10px, 15px, 20px, colorRange($black, 2));
}

@mixin dropshadow-inset {
    @include dropshadow(true, 5px, 5px, 10px, colorRange($black, 5));
}

@mixin linear-gradient($color, $hover) {
    @if($color =="primary") {
        @include gradient("linear", "135", $primary-0, $primary-1);
    }

    @else if(($color =="warning")) {
        @include gradient("linear", "135", $warning-0, $warning-1);
    }

    @else if(($color =="danger")) {
        @include gradient("linear", "135", $danger-0, $danger-1);
    }

    @else if(($color =="success")) {
        @include gradient("linear", "135", $success-0, $success-1);
    }

    @else if(($color =="silver")) {
        @include gradient("linear", "135", colorRange($white, 15), colorRange($white, 20));
    }

    @else if(($color =="dark")) {
        @include gradient("linear", "45", colorRange($primary-dark-0, 3), colorRange($primary-dark-0, 8));
    }

    @if($hover) {
        background-size: 150%;
        background-position-x: 0%;
        background-position-y: 75%;
        background-repeat: no-repeat;

        &:hover {
            background-position-x: 100%;
        }
    }
}

@mixin rounded($size, $direction) {
    @if($direction =="t" or $direction =="y" or $direction =="xy") {
        border-top-left-radius: $size;
    }

    @if($direction =="r" or $direction =="x" or $direction =="xy") {
        border-top-right-radius: $size;
    }

    @if($direction =="b" or $direction =="y" or $direction =="xy") {
        border-bottom-right-radius: $size;
    }

    @if($direction =="l" or $direction =="x" or $direction =="xy") {
        border-bottom-left-radius: $size;
    }
}

@mixin split($direction) {
    @if($direction =="t" or $direction =="y" or $direction =="xy") {
        border-top: #{"1px solid "+$light};
    }

    @if($direction =="r" or $direction =="x" or $direction =="xy") {
        border-right: #{"1px solid "+$light};
    }

    @if($direction =="b" or $direction =="y" or $direction =="xy") {
        border-bottom: #{"1px solid "+$light};
    }

    @if($direction =="l" or $direction =="x" or $direction =="xy") {
        border-left: #{"1px solid "+$light};
    }
}
@mixin splitDark($direction) {
    @if($direction =="t" or $direction =="y" or $direction =="xy") {
        border-top: #{"1px solid "+$dark} !important;
    }

    @if($direction =="r" or $direction =="x" or $direction =="xy") {
        border-right: #{"1px solid "+$dark} !important;
    }

    @if($direction =="b" or $direction =="y" or $direction =="xy") {
        border-bottom: #{"1px solid "+$dark} !important;
    }

    @if($direction =="l" or $direction =="x" or $direction =="xy") {
        border-left: #{"1px solid "+$dark} !important;
    }
}

@mixin xyProps($key, $direction, $value) {
    @if($direction =="t" or $direction =="y" or $direction =="xy") {
        #{$key+"-top"}: #{$value};
    }

    @if($direction =="r" or $direction =="x" or $direction =="xy") {
        #{$key+"-right"}: #{$value};
    }

    @if($direction =="b" or $direction =="y" or $direction =="xy") {
        #{$key+"-bottom"}: #{$value};
    }

    @if($direction =="l" or $direction =="x" or $direction =="xy") {
        #{$key+"-left"}: #{$value};
    }
}

// @include xyProp("split", "border","1px solid "+$light);
// @include xyProp("border-light-1", "border",$lightborder-1);
// @include xyProp("border-trans-1", "border",$transborder-1);
// @include xyProp("border-light-2", "border",$lightborder-2);
// @include xyProp("border-trans-2", "border",$transborder-2);
@use '../../theme' as *;

.backGifAnimaton {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // background: url(../../assets/video/welcome.mp4) no-repeat center;
    // background: url(https://platform.ottomatically.com/uploads/welcome.d72f06ee.gif) no-repeat center;
    background-size: 130% auto;
   
}

@use '../../theme' as *;

.userPage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .userContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .bubbleCon{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            filter: blur($blur5);

            &Inner{
                // filter: url(#goo);
                width: 100%;
                height: 100%;
                opacity: 0.2;
                .bubble{
                    &0,&1{
                        @include rounded($radius-px, "xy");
                        position: absolute;
                        transform: translate(-50%,-50%);
                    }
                    @for $i from 1 through 100 {
                        &0:nth-child(#{$i}),&1:nth-child(#{$i}) { 
                            top: #{random(100) + "%"};
                            left: #{random(100) + "%"};
                            $a: random(300) + px;
                            width: $a;
                            height: $a;
                        }
                    }
                    &0{
                        // background-color: colorRange($primary-0,5);
                        background-color: $primary-0;
                    }
                    &1{
                        // background-color: colorRange($primary-1,5);
                        background-color: $primary-1;
                    }
                }
            }
        }
        .userCard{
            @include rounded($radius-5, "xy");
            @include xyProps("border", "xy", "" + $lightborder-5);
            box-shadow: 10px 20px 80px 0px colorRange($black, 1);
            width: 1300px;
            height: 700px;
            display: flex;
            background-color: colorRange($white, 10);
            padding: 50px 0;
            position: relative;
            overflow: hidden;
            transition: $transition-0-1;
            &.fullScreen{
                width: 100%;
                height: 100vh;
                @include rounded(0, "xy");
                padding: 0px 0 50px 0;
            }
            &Blur{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                @include blur($blur5);
                z-index: -1;
            }
            .mini{
                &Nav,&Footer{
                    position: absolute;
                    width: 100%;
                    left: 0;
                    display: flex;
                    gap: 8px;
                    a{
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
                &Nav{
                    top: 0;
                    justify-content: end;
                    padding: 20px 50px;
                    z-index: 1;
                    a{
                        color: colorRange($muted, 10);
                        padding: 5px 10px;
                        @include rounded($radius-3, "xy");
                        font-weight: bold;
                        &:not(#signIn):hover{
                            @include linear-gradient("primary", false);
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                        &:not(#signIn).active{
                            @include linear-gradient("primary", false);
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    #signIn{
                        @include linear-gradient("primary", false);
                        color: $white;
                    }
                }
                &Footer{
                    bottom: 0;
                    padding: 5px 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    a{
                        img{
                            width: 40px;
                            opacity: 0.5;
                        }
                        &:last-child img{
                            margin: 0 -8px;
                        }
                        &:hover img{
                            opacity: 1;
                        }
                    }
                    &Nav{
                        display: flex;
                        gap: 8px;
                        color: colorRange($muted, 10);
                        a{
                            color: colorRange($muted, 10);
                            &:hover{color: colorRange($muted, 17);}
                            &.active{color: colorRange($muted, 17);}
                        }
                    }
                }
            }
            .poweredLogo{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: $white;
                @include rounded($radius-3, "t");
                @include rounded($radius-3, "r");
                padding: 10px 20px 5px 20px;
                box-shadow: 0 -5px 20px colorRange($black, 1);
                display: flex;
                align-items: center;
                &:hover{
                    text-decoration: none;
                }
                h5{
                    margin-bottom: 0;
                    margin-right: 10px;
                    font-size: 16px;
                }
                img{
                    height: 30px;
                }
            }
        }
    }
}
@media (max-width: 412px) {
    .userCard{

        height: 100vh; 
        padding: 50px 0; 
    }
}
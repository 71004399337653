@use "../../theme" as *;

.tabButton {
    @include rounded($radius-4, "b");
    @include rounded($radius-4, "l");
    // @include xyProps("border", "xy", "" +$lightborder-2);
    @include linear-gradient("silver", false);
    // line-height: 40px;
    min-width: 50px;
    text-align: center;
    border: 0px;
    margin-right: 3px;
    padding: 10px 15px;

    &.active {
        @include dropshadow-1;
        color: $primary-1;
    }
}
.overviewHeading{
    cursor: pointer;
    width: 100%;
    background-color: #f1f1f1;
    border: 2px dashed #2087fb;
}